import { React, useEffect } from "react";
import { ThemeProvider } from "@mui/material/styles";
import theme from "../theme";
import { Card, Box, CircularProgress } from "@mui/material";
import Requests from "../logic/Requests";
import useFetch from "../hooks/useFetch";
import { useParams } from "react-router-dom";
import useZustant from "../hooks/useZustant";
import UnitDetails from "../components/unit/UnitDetails";
import EditorComponent from "../components/unit/Editor";
import Utils from "../utils";
import Hotkeys from "react-hot-keys";
import useShow from "../hooks/useShow";
import RejectUnitModal from "../components/unit/RejectUnitModal";
import useAlert from "../hooks/useAlert";
import CustomSnackBarAlert from "../components/global/CustomSnackBarAlert";
import Config from "../config/index";

export default function Unit() {
  const { user } = useZustant();
  let userHotkeys;
  if (user.scribeKeys && user.scribeKeys !== "") {
    userHotkeys = Object.entries(user.scribeKeys).map(([key, value]) => {
      return {
        ...Utils.DEFAULT_KEYS[key],
        ...value,
      };
    });
  } else {
    userHotkeys = Utils.DEFAULT_KEYS;
  }
  useEffect(() => {
    document.title = "Unit Details - Wordibly";
    document.addEventListener("keydown", (e) => {
      if (user.rolename === "Transcriber") {
        Object.entries(userHotkeys).forEach(([key, value]) => {
          if (value.key === e.key) {
            e.preventDefault();
          }
        });
      }
    });
  }, []);

  const { show, close, open } = useShow();
  const {
    show: showALert,
    close: closeAlert,
    currentAlertState,
    handleAlert,
  } = useAlert();

  let { idTranscript, idUnit } = useParams();
  const { data, isLoading } = useFetch(Requests.getTranscript, idTranscript);
  const { data: dataUnit, isLoading: isLoadingUnit } = useFetch(
    Requests.getUnit,
    idUnit
  );
  const { data: rejectedReasons } = useFetch(
    Requests.getRejectionsReasons,
    idUnit
  );

  let unit, transcriberNotes, fileType;
  if (!isLoading) {
    if (!isLoadingUnit) {
      if (!data.new_editor) {
        window.location = `${Config.oldUI}/projects/${data.project._id}/transcript/${data._id}/unit/${idUnit}`;
      }
    }
    unit = data.units.filter((unit) => unit._id === idUnit)[0];
    unit.new_editor_transcript = data.new_editor
    fileType = data.file_type;
    transcriberNotes = [
      ...new Set([...data.project.transcriber_notes, ...data.notes]),
    ];
    if (data.timestamps) {
      transcriberNotes.push(
        `ONLY APPLIES WHEN MANUALLY TIMESTAMPING: This unit is not the first unit in this transcript. It is ${Utils.toHHMMSS(
          unit.offset
        )} into the original audio. Please start your timestamps accordingly.`
      );
    }
  }

  return !isLoading ? (
    <ThemeProvider theme={theme}>
      <RejectUnitModal
        show={show}
        close={close}
        idUnit={idUnit}
        user={user}
        handleAlert={handleAlert}
      />
      <CustomSnackBarAlert type="error" show={showALert} close={closeAlert}>
        {currentAlertState.message}
      </CustomSnackBarAlert>
      <Box sx={{ mt: 3, mx: { xs: 2, md: 10 } }}>
        <Card component="main" style={{ padding: 10 }}>
          {!rejectedReasons ? null : (
            <UnitDetails
              data={{ user, data, unit, transcriberNotes, rejectedReasons }}
              style={{ marginBottom: 10 }}
            />
          )}
          {!dataUnit ? null : (
            <EditorComponent
              data={{
                fileType,
                length: unit.length,
                useNewEditor: data.project.new_editorr,
                dataUnit,
                unit,
                multichannel: data.multichannel,
              }}
              hasSpeakersColumns={data.has_speakers_columns}
              open={open}
            />
          )}
        </Card>
      </Box>
    </ThemeProvider>
  ) : (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <>
        <CircularProgress color="fourth" />
      </>
    </Box>
  );
}
