import { React } from "react";
import {
  Alert,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Card,
  CardMedia,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ThemeProvider } from "@mui/material/styles";

import wordiblyLogo from "../assets/img/wordiblyLogo.webp";
import theme from "../theme";
   
export default function Login_focusfwd(props) {
  const { handleSubmit, error, alertError, errorFields, loading, open } = props;

  return (
    <ThemeProvider theme={theme}>
         <Card
        component="main"
        sx={{ p: 4, maxWidth: 900, boxSizing: "border-box" }}
      >
        <CardMedia
          component="img"
          height="100"
          sx={{ objectFit: "contain" }}
          image={wordiblyLogo}
        />
        <Box
          container
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxWidth: { xs: "100%", md: 300 },
            }}
            style={{ marginTop: 10 }}
          >
            <Typography variant="h1" xs={{ fontWeight: "bold" }}>
              Sign In
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              noValidate
              sx={{ display: "block" }}
            >
              {error && (
                <Alert variant="outlined" severity="error">
                  {alertError}
                </Alert>
              )}
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                variant="filled"
                autoComplete="email"
                error={errorFields}
                autoFocus
                helperText={errorFields ? "Incorrect entry." : ""}
                display={"block"}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                variant="filled"
                type="password"
                error={errorFields}
                id="password"
                autoComplete="current-password"
                helperText={errorFields ? "Incorrect entry." : ""}
                display={"block"}
              />
              <LoadingButton
                type="submit"
                color="primary"
                variant="contained"
                //sx={{ mt: 3, mb: 2, p:{ xs:"" ,md:" 16px 32px 16px 32px"}, width:{xs: "100%", md:""}}}
                sx={{ width: { xs: "100%", md: "100px" } }}
                display={"block"}
                loading={loading}
              >
                Login
              </LoadingButton>
              <Grid container>
                <Grid item xs>
                  <Link href="#" variant="body2" onClick={() => open()}>
                    Forgot password?
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </Card>
    </ThemeProvider>
  );
}
